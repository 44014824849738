<template>
  <div class="container">
    <div
      class="left-menu"
      :style="{
        width: !isCollapse ? '12%' : '4.5%',
        textAlign: !isCollapse ? 'left' : 'center',
      }"
    >
      <left-list :leftIndex="'8'" :isCollapse="isCollapse"></left-list>
    </div>
    <div
      class="right-content"
      :style="{ width: !isCollapse ? '88%' : '95.5%' }"
    >
      <div class="top-nav">
        <top-header @openMenu="openMenu" :isCollapse="isCollapse"></top-header>
      </div>
      <div class="left-list">
        <div class="left-list-title">
          <span>设置</span>
        </div>
        <div class="left-list-item" @click="leftClick('0')">
          <span>收货地址</span>
        </div>
        <div class="left-list-item" @click="leftClick('1')">
          <span>闲赚海报</span>
        </div>
        <div class="left-list-item left-list-active" @click="leftClick('2')">
          <span>闲赚比例</span>
        </div>
        <div class="left-list-item" @click="leftClick('3')">
          <span>驳回原因</span>
        </div>
        <div class="left-list-item" @click="leftClick('4')">
          <span>客服</span>
        </div>
      </div>
      <div class="right-next">
        <div class="title">
          <span>闲赚比例</span>
        </div>
        <div class="mt-20">
          <div class="address-from">
            <div class="from-item">
              <span class="txt-red">*</span>
              <span class="from-label">个人分销商佣金比例：</span>
              <div class="from-item-input">
                <input type="number" v-model="fromList.single_dealer" />
              </div>
              %
            </div>
            <div class="from-item">
              <span class="txt-red">*</span>
              <span class="from-label">企业分销商佣金比例：</span>
              <div class="from-item-input">
                <input type="number" v-model="fromList.company_dealer" />
              </div>
              %
            </div>
            <div class="from-item">
              <el-button type="primary" @click="moneySubmit">提交</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import leftList from "../../../components/leftList.vue";
import topHeader from "../../../components/header.vue"
export default {
  components: {
    leftList,
    topHeader
  },
  inject: ["reload"],
  data() {
    return {
      isCollapse: false,
      fromList: {
        single_dealer: "",
        company_dealer: "",
      },
    };
  },
  created() {
    this.getMoney();
  },
  methods: {
    moneySubmit() {
      if(this.fromList.single_dealer > 100){
          this.$notify({
          title: '提示',
          message: '个人佣金比例不可大于100%',
          type: 'warning'
        });
        return false;
      }
      if(this.fromList.company_dealer > 100){
          this.$notify({
          title: '提示',
          message: '企业佣金比例不可大于100%',
          type: 'warning'
        });
        return false;
      }
      this.fromList.single_dealer = this.fromList.single_dealer / 100;
      this.fromList.company_dealer = this.fromList.company_dealer / 100;
      this.$post("upd/dealer_setting", {
        dealer_value: this.fromList,
      }).then((res) => {
        if (res.data.status == 200) {
          this.$notify({
            title: "提示",
            message: "修改成功！",
            type: "success",
          });
          this.getMoney();
        } else {
          this.$notify.error({
          title: '提示',
          message: res.data.message
        });
        }
      });
    },
    getMoney() {
      this.$get("get/dealer_setting", {}).then((res) => {
        this.fromList.single_dealer = res.data.data.single_dealer * 100;
        this.fromList.company_dealer = res.data.data.company_dealer * 100;
        console.log(res);
      });
    },
    leftClick(index) {
      if (index == 0 && this.$route.path != "/setup") {
        this.$router.push("/setup");
      } else if (index == 1 && this.$route.path != "/poster") {
        this.$router.push("/poster");
      } else if (index == 2 && this.$route.path != "/proportion") {
        this.$router.push("/proportion");
      } else if (index == 3 && this.$route.path != "/reject") {
        this.$router.push("/reject");
      } else if (index == 4 && this.$route.path != "/customer") {
        this.$router.push("/customer");
      } else {
        this.reload();
      }
    },
    openMenu() {
      this.isCollapse = !this.isCollapse;
    },
  },
};
</script>

<style lang="scss" scoped>
.right-content {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  vertical-align: top;
  display: inline-block;
  &::-webkit-scrollbar {
    display: none;
  }
  .address-from {
    margin-top: 30px;
    padding-left: 150px;
    .from-item {
      margin-top: 50px;
      .from-label {
        display: inline-block;
        width: 200px;
      }
      .from-item-select {
        display: inline-block;
        margin-left: 30px;
      }
      .from-item-input {
        display: inline-block;
        width: 200px;
        border-bottom: 1px solid #bbb5b5;
        margin-left: 30px;
        input {
          width: 190px;
          border: 0px;
          outline: none;
        }
      }
    }
  }
  .left-list {
    width: 10%;
    height: 93vh;
    overflow-x: hidden;
    overflow-y: scroll;
    font-size: 14px;
    border-right: 20px solid #f2f2f2;
    display: inline-block;
    text-align: center;
    &::-webkit-scrollbar {
      display: none;
    }
    &-title {
      margin: 20px 0;
      font-size: 16px;
    }
    &-item {
      padding: 15px 0;
      cursor: pointer;
      transition: all 0.2s;
      &:hover {
        color: #fff;
        background-color: #3291f8;
      }
    }
    &-active {
      color: #fff;
      background-color: #3291f8;
    }
  }
  .right-next {
    height: 93vh;
    width: 88.5%;
    overflow-x: hidden;
    overflow-y: scroll;
    border-top: 20px solid #f2f2f2;
    padding: 20px;
    box-sizing: border-box;
    display: inline-block;
    vertical-align: top;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .title {
    color: #000;
    height: 10px;
    padding-left: 15px;
    line-height: 10px;
    border-left: 5px solid #80c4f8;
    margin-bottom: 10px;
  }

  .top-nav {
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    padding: 15px;

    .left-icon {
      i {
        cursor: pointer;
        color: #3291f8;
        font-size: 30px;
      }

      .el-icon-refresh-right {
        margin-left: 30px;
      }
    }

    .right-user {
      font-size: 14px;

      span {
        vertical-align: middle;
        margin-right: 20px;
      }

      .colse-btn {
        cursor: pointer;
        vertical-align: middle;
        border: 1px solid #999;
        display: inline-block;
        padding: 5px 20px;
        border-radius: 50px;

        span {
          margin-right: 10px;
        }

        i {
          vertical-align: middle;
        }
      }
    }
  }
}

.left-menu {
  display: inline-block;

  .iconyonghuzu {
    font-size: 18px;
    margin: 0 5px;
  }
}

.el-menu {
  width: 100%;
  background-color: #20222a;
  height: 100vh;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  background-color: #20222a;
  color: #fff;
  height: 100vh;
  width: 100%;
}

.el-menu-item:focus,
.el-menu-item:hover {
  color: #000;
  background-color: #ecf5ff;
}

.el-menu-item {
  color: #fff;

  i {
    color: #3291f8;
  }
}

.el-menu-item.is-active {
  background-color: #ecf5ff;
  color: #20222a;
}

.menu-h5 {
  margin: 20px;
  text-align: center;
  font-size: 20px;
}

.menu-icon {
  width: 100%;
  color: #fff;
  box-sizing: border-box;
  padding: 30px 22px;
}
</style>
